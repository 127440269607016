import { createSlice } from '@reduxjs/toolkit';
import { getWalletStats } from './walletActions';


const initialState = {
	balance: 0,
	expenses: 0,
	income: 0,
	withdraws: 0,
	pending: 0,
	refunded: 0,
};

const walletSlice = createSlice({
	name: 'wallet',
	initialState,
	reducers: {
	},
	extraReducers: {
		[getWalletStats.fulfilled]: (state, action) => {
			state.balance = action.payload.balance ? action.payload.balance : 0;
			state.expenses = action.payload.expenses ? action.payload.expenses : 0;
			state.income = action.payload.income ? action.payload.income : 0;
			state.withdraws = action.payload.withdraws ? action.payload.withdraws : 0;
			state.pending = action.payload.pending ? action.payload.pending : 0;
			state.refunded = action.payload.refunded ? action.payload.refunded : 0;
		},
	},
});

export default walletSlice.reducer;

// export const { setCredentials, setUser } = walletSlice.actions;
