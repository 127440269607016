import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosConfig from '../../utils/axiosConfig';
import { getDeviceTimeZone, getProfileAdmin, getProfileStudent, getProfileTutor } from '../../utils';

export const createNewProfile = createAsyncThunk('user/createProfile', async ({
	firstName, lastName, email, password
}, { rejectWithValue }) => {
	try {
		const response = await axiosConfig.post('/auth/profile/create', {
			firstName: firstName,
			lastName: lastName,
			email: email,
			password
		}, {
			withCredentials: false
		});

		return {
			firstName: firstName,
			lastName: lastName,
			email: email,
			password
		};
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});


export const getUserProfiles = createAsyncThunk('user/profiles', async ({userId = undefined}, { rejectWithValue, getState }) => {
	try {
		const user = userId ? userId : getState().auth.user._id;

		const response = await axiosConfig.get('/profile/all/' + user, {
			withCredentials: false,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
		console.log(response)
		return await response.data;
	} catch (error) {
		console.log(error)
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const getUserWithProfiles = createAsyncThunk('users/get/full', async ({userId = undefined}, { rejectWithValue, getState }) => {
	try {
		console.log(userId)
		const response = await axiosConfig.get('/users/get/' + userId, {
			withCredentials: false,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
		return await response.data;
	} catch (error) {
		console.log(error)
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const completeStudentProfile = createAsyncThunk('user/profile', async ({
	userId, firstName, lastName, country, description, phone, avatar, timezone
}, { rejectWithValue }) => {
	try {
		let formData = new FormData();

		formData.append("_id", userId);
		formData.append("file", avatar[0]);
		formData.append("firstName", firstName);
		formData.append("lastName", lastName);
		formData.append("country", country);
		formData.append("timezone", timezone);
		formData.append("phone", phone);

		const response = await axiosConfig.post('/profile/student/create', formData, {
			withCredentials: false,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

		return await response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const fillTutorInformations = createAsyncThunk('users/tutor/informations', async ({
	firstName, lastName, country, description, phone, avatar, video, timezone
}, { rejectWithValue, getState }) => {
	try {
		const userId = getState().auth.user._id;

		let formData = new FormData();

		formData.append("_id", userId);
		formData.append("file", avatar[0]);
		formData.append("firstName", firstName);
		formData.append("lastName", lastName);
		formData.append("country", country);
		formData.append("phone", phone);
		formData.append("timezone", timezone);

		const response = await axiosConfig.post('/profile/tutor/informations', formData, {
			withCredentials: false,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

		return response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const completeTutorProfile = createAsyncThunk('profile/tutor/complete', async (_, { rejectWithValue, getState }) => {
	try {
		let tutor = getProfileTutor();

		let data = {
			_id: tutor._id,
		}

		const response = await axiosConfig.post('/profile/tutor/complete', data, {
			withCredentials: false
		});

		return true
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const getFullTutorProfile = createAsyncThunk('users/tutor/profile/full', async ({
	profileId
}, { rejectWithValue, getState }) => {
	try {
		let data = {
			userId: getState().profile.currentProfile._id,
			id: profileId
		}
		const response = await axiosConfig.post('/profile/full/', data, {
			withCredentials: false
		});
		return await response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const fillTutorLanguages = createAsyncThunk('users/tutor/languages', async ({ teach, speak, tutorId, video, description }, { rejectWithValue, getState }) => {
	try {
		let tutor = getProfileTutor();
		console.log(tutor)
		let data = {
			_id: tutor._id,
			teachs: teach,
			speak: speak,
			youtube: video,
			description: description
		}
		const response = await axiosConfig.post('/profile/tutor/languages', data, {
			withCredentials: false,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

		return await response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const editTutorLanguages = createAsyncThunk('users/tutor/languages', async ({ teach, speak, tutorId, video, description, hideProfile }, { rejectWithValue, getState }) => {
	try {
		let tutor = getProfileTutor();
		console.log(tutor)
		let data = {
			_id: tutor._id,
			teachs: teach,
			speak: speak,
			youtube: video,
			description: description,
			hideProfile: hideProfile
		}
		const response = await axiosConfig.post('/profile/tutor/edit/languages', data, {
			withCredentials: false,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

		return await response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const fillTutorFormations = createAsyncThunk('profile/tutor/formations', async ({ formations, tutorId, noEducation }, { rejectWithValue, getState }) => {
	try {
		let tutor = getProfileTutor();

		if (noEducation) {
			let data = {
				"tutor": tutor._id
			}
			const response = await axiosConfig.patch('/profile/tutor/formations', data, {
				withCredentials: false,
			});

			return [];
		} else {
			var promises = []
			for (let i = 0; i < formations.length; i++) {

				let formData = new FormData();
				formData.append("label", formations[i].label);
				formData.append("file", formations[i].file[0]);
				formData.append("profileId", tutor._id);
				formData.append("from", formations[i].from);
				formData.append("to", formations[i].to);
				formData.append("toPresent", formations[i].toPresent);
				formData.append("school", formations[i].school);
				formData.append("description", formations[i].description);

				promises.push(() => axiosConfig.post('/profile/tutor/formations', formData, {
					withCredentials: false,
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}))
			}

			return await Promise.all(promises.map(v => v())).then((responses) => responses.map(v => v.data))
		}

	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const fillTutorExperience = createAsyncThunk('users/tutor/experiences', async ({ experience, tutorId, noExperience }, { rejectWithValue, getState }) => {
	try {
		let tutor = getProfileTutor();

		if (noExperience) {
			let data = {
				"tutor": tutor._id
			}
			const response = await axiosConfig.patch('/profile/tutor/experiences', data, {
				withCredentials: false,
			});

			return [];
		} else {
			var promises = []
			for (let i = 0; i < experience.length; i++) {

				let formData = new FormData();
				formData.append("label", experience[i].label);
				formData.append("file", experience[i].file[0]);
				formData.append("profileId", tutor._id);
				formData.append("from", experience[i].from);
				formData.append("to", experience[i].to);
				formData.append("toPresent", experience[i].toPresent);
				formData.append("school", experience[i].school);
				formData.append("description", experience[i].description);

				promises.push(() => axiosConfig.post('/profile/tutor/experiences', formData, {
					withCredentials: false,
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}))
			}

			return await Promise.all(promises.map(v => v())).then((responses) => responses.map(v => v.data))
		}

	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const fillTutorCertificate = createAsyncThunk('users/tutor/certificates', async ({ certificates, tutorId, noCertificate }, { rejectWithValue, getState }) => {
	try {
		let tutor = getProfileTutor();

		if (noCertificate) {
			let data = {
				"tutor": tutor._id
			}
			const response = await axiosConfig.patch('/profile/tutor/certificates', data, {
				withCredentials: false,
			});

			return [];
		} else {
			var promises = []
			for (let i = 0; i < certificates.length; i++) {

				let formData = new FormData();
				formData.append("label", certificates[i].label);
				formData.append("file", certificates[i].file[0]);
				formData.append("year", certificates[i].year);
				formData.append("profileId", tutor._id);
				formData.append("description", certificates[i].description);

				promises.push(() => axiosConfig.post('/profile/tutor/certificates', formData, {
					withCredentials: false,
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}))
			}

			return await Promise.all(promises.map(v => v())).then((responses) => responses.map(v => v.data))
		}

	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const deleteTutorCertificate = createAsyncThunk('profile/tutor/delete/certificate', async ({ id, name }, { rejectWithValue, getState }) => {
	try {
		let tutor = getProfileTutor();
		let data = {
			profile: tutor._id,
			id: id,
			type: name
		}

		const response = await axiosConfig.delete('/profile/tutor/certificates', { data: data }, {
			withCredentials: false,
		});

		return data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const createTutorProfile = createAsyncThunk('profile/tutor/create', async (_, { rejectWithValue, getState }) => {
	try {
		const userId = getState().auth.user._id;

		const response = await axiosConfig.post('/profile/tutor/create', { userId: userId }, {
			withCredentials: false,
		});

		return response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const getModerationList = createAsyncThunk('profile/tutor/moderate/list', async (_, { rejectWithValue, getState }) => {
	try {
		const response = await axiosConfig.get('/profile/tutor/moderate/list', {
			withCredentials: false,
		});

		return response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const getTutorFullProfile = createAsyncThunk('profile/tutor/moderate/list', async ({ tutorId }, { rejectWithValue }) => {
	try {
		console.log(tutorId);
		const response = await axiosConfig.get('/profile/tutor/full/' + tutorId, {
			withCredentials: false,
		});

		return response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const moderateTutor = createAsyncThunk('profile/tutor/moderate', async ({ tutorId, decision }, { rejectWithValue }) => {
	try {
		let data = {
			tutor: tutorId,
			decision
		}
		console.log(data);
		const response = await axiosConfig.post('/profile/tutor/moderate', data, {
			withCredentials: false,
		});

		return response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const switchToAdminProfile = createAsyncThunk('profile/admin/switch', async (_, { rejectWithValue, getState }) => {
	try {
		const userId = getState().auth.user._id;
		const admin = getProfileAdmin();
		const response = await axiosConfig.post('/profile/switch', { profile: admin._id, userId }, {
			withCredentials: false,
		});

		return response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const switchToStudentProfile = createAsyncThunk('profile/student/switch', async (_, { rejectWithValue, getState }) => {
	try {
		const userId = getState().auth.user._id;
		const student = getProfileStudent();
		const response = await axiosConfig.post('/profile/switch', { profile: student._id, userId }, {
			withCredentials: false,
		});

		return response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const switchToTutorProfile = createAsyncThunk('profile/tutor/switch', async (_, { rejectWithValue, getState }) => {
	try {
		const userId = getState().auth.user._id;
		const tutor = getProfileTutor();
		const response = await axiosConfig.post('/profile/switch', { profile: tutor._id, userId }, {
			withCredentials: false,
		});

		return response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const tutorLastStep = createAsyncThunk('profile/tutor/laststep', async ({ days, prices }, { rejectWithValue, getState }) => {
	try {
		const userId = getState().auth.user._id;
		const tutor = getProfileTutor();
		const data = {
			user: userId,
			profile: tutor._id,
			businessHours: {
				profile: tutor._id,
				days: days,
				timezone: getDeviceTimeZone()
			},
			prices: prices
		}
		const response = await axiosConfig.post('/profile/tutor/laststep', data, {
			withCredentials: false,
		});

		return response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const createStudent = createAsyncThunk('profile/become/student', async (_, { rejectWithValue, getState }) => {
	try {
		const userId = getState().auth.user._id;
		const data = {
			user: userId,
		}
		const response = await axiosConfig.post('/profile/become/student', data, {
			withCredentials: false,
		});

		return response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const getTutorList = createAsyncThunk('profile/tutor/list', async (_, { rejectWithValue, getState }) => {
	try {
		const userId = getState().auth.user._id;
		const data = {
			user: userId,
		}
		const response = await axiosConfig.post('/profile/tutor/list', data, {
			withCredentials: false,
		});

		return response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const searchTutor = createAsyncThunk('profile/search/tutor', async ({ signal, ...criteria }, { rejectWithValue, getState }) => {
	try {
		const userId = getState().auth.user._id;

		console.log("---", userId)
		const data = {
			user: "65d5b19719dc8c3d88534846",
			...criteria
		}
		console.log(data)
		const response = await axiosConfig.post('/profile/search/tutor', data, {
			withCredentials: false,
			signal: signal
		});

		return response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

export const addReview = createAsyncThunk('profile/tutor/review', async (review, { rejectWithValue, getState }) => {
	try {
		const data = {
			student: getState().profile.currentProfile._id,
			...review
		}
		console.log(data)
		const response = await axiosConfig.post('/profile/tutor/review', data, {
			withCredentials: false,
		});

		return response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});


export const getDashboardStat = createAsyncThunk('profile/dashboard/stat', async (review, { rejectWithValue, getState }) => {
	try {
		const data = {
			profile: getState().profile.currentProfile._id,
			role: getState().profile.currentProfile.role,
		}

		const response = await axiosConfig.post('/profile/dashboard/stat', data, {
			withCredentials: false,
		});

		return response.data;
	} catch (error) {
		return rejectWithValue({
			error: error.response.data ? error.response.data.message : error.message
		});
	}
});

