import { Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { PrivateRoute } from '../privateRoute';

import { FullWidth } from '../../layouts/fullWidth';
import { AuthLayout } from '../../layouts/authLayout';

import { CompleteTutorProfile } from '../profile/completeTutorProfile';
import { TutorProfile } from '../profile/tutorProfile';

import { setProfile } from '../../store/profile/profileSlice';
import { getUserProfiles } from '../../store/profile/profileActions';
import { verifyUserDetails } from '../../store/auth/authActions';
import { getWalletStats } from '../../store/wallet/walletActions';

import { Home } from '../../pages/home/home';
import { Login } from '../../pages/login';
import { Register } from '../../pages/register';
import { UsersList, TutorsModerationList, TutorModerate, CreateAdmin } from '../../pages/users'
import { CreateProfile } from '../../pages/profile/createProfile';
import { UserProfile } from '../../pages/users/userProfile';
import Chat from '../../pages/chat';
import { CalendarView } from '../../pages/calendar/calendar';
import { Settings } from '../../pages/settings/settings';
import { TutorList } from '../../pages/tutors/tutorList';
import { AppointmentList } from '../../pages/appointment/list';
import { AdminAppointmentList } from '../../pages/appointment/adminList';
import { ForgotPassword } from '../../pages/forgot/forgot';
import { ResetPassword } from '../../pages/forgot/resetPassword';
import { SwitchTutor } from '../../pages/profile/switchTutor';
import { LoadingPage } from '../../pages/general/loading-page';
import { AppointmentDetails } from '../../pages/appointment/detail';
import { Wallet } from '../../pages/wallet/wallet';
import { PaymentResult } from '../../pages/appointment/paymentResult';
import { Withdraw } from '../../pages/wallet/withdraw';
import SupportPage from '../../pages/admin/support/support';
import { SettingPreview } from '../../pages/settings/preview';

import Styles from './app.module.scss';

function App() {
	const dispatch = useDispatch();
	const { user, accessToken, refreshToken } = useSelector((state) => state.auth);
	const [loading, setLoading] = useState(true)


	useEffect(() => {
		if (accessToken) {
			dispatch(verifyUserDetails(refreshToken))
			.unwrap()
			.then(async (data) => {
				if(data.user.profile){
					await dispatch(setProfile(data.user.profile))
					await dispatch(getWalletStats())
				}
				await dispatch(getUserProfiles())
				setLoading(false)
			})
			.catch(() =>{
				setLoading(false)
			})
		}else{
			setLoading(false)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			{loading ? <LoadingPage /> :
			<Layout>
				<Routes>
					<Route path="/" element={<PrivateRoute component={Home} />} />
					<Route path='/create/profile' element={<FullWidth component={CreateProfile} restricted={true} />} />
					<Route path='/switch/tutor' element={<FullWidth component={SwitchTutor} restricted={true} isSwitch={true}/>} />
					<Route path='/login' element={<AuthLayout restricted={true} component={Login} />} />
					<Route path='/register' element={<AuthLayout restricted={true} component={Register} />} />
					<Route path='/forgot' element={<AuthLayout restricted={true} component={ForgotPassword} />} />
					<Route path='/password-reset/:hash' element={<AuthLayout restricted={true} component={ResetPassword} />} />
					
					<Route path='/users/list' element={<PrivateRoute restricted={true} component={UsersList} />} />
					<Route path='/users/moderation' element={<PrivateRoute restricted={true} component={TutorsModerationList} />} />
					<Route path='/users/user/:userId' element={<PrivateRoute restricted={true} component={UserProfile} />} />
					<Route path='/users/moderate/:tutorId' element={<PrivateRoute restricted={true} component={TutorModerate} />} />
					
					<Route path='/chat' element={<PrivateRoute restricted={true} component={Chat} />} />
					<Route path='/chat/:id' element={<PrivateRoute restricted={true} component={Chat} />} />
					
					<Route path='/appointment' element={<PrivateRoute restricted={true} component={AppointmentList} />} />
					<Route path='/admin/appointment' element={<PrivateRoute restricted={true} component={AdminAppointmentList} />} />
					<Route path='/appointment/detail/:id' element={<PrivateRoute restricted={true} component={AppointmentDetails} />} />

					<Route path='/settings' element={<PrivateRoute restricted={true} component={Settings} />} />
					<Route path='/tutors' element={<PrivateRoute restricted={true} component={TutorList} />} />
					<Route path='/tutor/:id' element={<PrivateRoute restricted={true} component={TutorProfile} />} />
					<Route path='/laststep' element={<PrivateRoute restricted={true} component={CompleteTutorProfile} />} />
					<Route path='/me' element={<PrivateRoute restricted={true} component={SettingPreview} />} />
					
					<Route path='/calendar' element={<PrivateRoute restricted={true} component={CalendarView} />} />
					<Route path='/create/admin' element={<PrivateRoute restricted={true} component={CreateAdmin} />} />

					<Route path='/wallet' element={<PrivateRoute restricted={true} component={Wallet} />} />

					<Route path='/admin/support' element={<PrivateRoute restricted={true} component={SupportPage} />} />

					<Route path='/order/payment/:id' element={<PrivateRoute restricted={true} component={PaymentResult} />} />

					<Route path='/withdraw' element={<PrivateRoute restricted={true} component={Withdraw} />} />

				</Routes>
			</Layout>}
		</div>
	);
}

const Layout = ({ children }) => (
	<div className={Styles.mainContainer}>
		<div className={Styles.contentContainer}>
			{children}
		</div>
	</div>
);

export { App };
