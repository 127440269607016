import { useLocation, useNavigate } from "react-router"
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import { Table, UsersListTable } from "../../components/users/table";
import { usePagination } from "@table-library/react-table-library/pagination";

import Styles from './users.module.scss'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiEye } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { getUsersList } from "../../store/auth/authActions";
import { getImage } from "../../utils";
import PhoneInput from "react-phone-input-2";
import countriesMask from "../../utils/data/phoneMasks.json"

const UsersList = () => {
    const [search, setSearch] = useState("");

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const [nodes, setNodes] = useState([]);
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getUsersList())
        .unwrap()
        .then(v => setNodes(v))
    }, [])

    const COLUMNS = [
        {
            onHeaderCell: {
                maxWidth: "50px"
            },
            label: 'Avatar', class: "avatar", renderCell: (item) => {
                return <img className={Styles.avatar} src={getImage(item.avatar)} />
            },
        },
        {
            label: 'Firstname',
            renderCell: (item) => item.firstName
        },
        {
            label: 'Lastname',
            renderCell: (item) => item.lastName,
        },
        {
            label: 'Email',
            renderCell: (item) => item.email
        },
        {
            label: 'Phone',
            renderCell: (item) => (item.phone && item.phone?.trim() != "") ?<PhoneInput
            disabled={true}
            masks={countriesMask}
            value={item.phone}
            dropdownStyle= {{display: "none"}}
            inputStyle={{border:0, paddingLeft:0, cursor: "default"}}
            buttonClass="hidden"
            countryCodeEditable={false}
            onChange={phone => this.setState({ phone })}
          /> : ""
        },
        {
            label: 'Country', renderCell: (item) => {
                if(item.profiles.filter((p) => p.role == 1 ).length > 0)
                    return "";
                return <img src={`https://flagsapi.com/${item.country}/shiny/24.png`} />
            },
        },
        {
            label: 'Profile', renderCell: (item, index) => {
                return(
                    <div className={Styles.profileList}>
                        {item.profiles.map((profile, i) => {
                            return  profile.role === 2 ? 
                            <span className={`${Styles.profile} ${Styles.tutor}`}>Tutor</span> : 
                            profile.role === 1 ? <span className={`${Styles.profile} ${Styles.tutor}`}>Admin</span> 
                            : <span className={`${Styles.profile} ${Styles.student}`}>Student</span> 
                        })}
                    </div>
                )
            }
        },
        {
            label: '',
            renderCell: (item, index) => {
                return <Link className={Styles.viewBtn} to={`/users/user/${item._id}`}>
                    <FiEye />
                    <span>View</span>
                </Link>
            }
        },
    ];


    const theme = useTheme([getTheme(), {
        HeaderRow: `color: #191D51;`,
        HeaderCell: `width: auto;`,
        Cell:  `width: auto;`,
    }]);

    let data = { nodes };

    data = {
        nodes: data.nodes.filter((item) =>
            item.firstName.toLowerCase().includes(search.toLowerCase()) ||
            item.lastName.toLowerCase().includes(search.toLowerCase()) ||
            item.email.toLowerCase().includes(search.toLowerCase()) ||
            (item.phone && item.phone.toLowerCase().includes(search.toLowerCase()))
        ),
    };

    const pagination = usePagination(data, {
        state: {
            page: 0,
            size: 5,
        },
        onChange: onPaginationChange,
    });
    const navigate = useNavigate()
    const pageButtonCount = 5;
    const [minVisibleBtn, setMinVisibleBtn] = useState(0)
    const [maxVisibleBtn, setMaxVisibleBtn] = useState(pageButtonCount)
    function onPaginationChange(action, state) {
        console.log(action, state);
        // let canShow = (index + pagination.state.page >= Math.floor(maxPageButton/2) || index + pagination.state.page == 0) && index + pagination.state.page + maxPageButton <= Math.floor(maxPageButton/2)
        setMinVisibleBtn(state.page - Math.floor(pageButtonCount / 2) >= 0 ? state.page - Math.floor(pageButtonCount / 2) : 0)
        setMaxVisibleBtn((minVisibleBtn + pageButtonCount) - 1)
    }

    return (
        <div className={Styles.tableContainer}>
            <div className={Styles.tableHeader}>
                <div className="flex items-center justify-start">
                    <h2 className="text-nt-blue">Users List</h2>
                    <button className="bg-nt-blue text-white px-2 py-1 ms-2 rounded" onClick={() => navigate("/create/admin")}>Add Administrator</button>
                </div>
                <label htmlFor="search">
                    <input id="search" type="text" placeholder="Search" className={Styles.search} value={search} onChange={handleSearch} />
                </label>
            </div>

            <CompactTable columns={COLUMNS} data={data} theme={theme} pagination={pagination} />
            <div className={Styles.pagination}>
                <div className={Styles.paginationInfos}>
                    Total pages: {pagination.state.getTotalPages(data.nodes)}
                </div>
                <div className={Styles.paginationButtons}>
                    <button
                        type="button"
                        disabled={minVisibleBtn === 0}
                        onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
                    >
                        {"<"}
                    </button>
                    {pagination.state.getPages(data.nodes).map((_, index) => {
                        return (minVisibleBtn <= index && maxVisibleBtn >= index) && <button
                            key={index}
                            type="button"
                            className={pagination.state.page === index ? Styles.active : ''}
                            onClick={() => pagination.fns.onSetPage(index)}
                        >
                            {index + 1}
                        </button>

                    })}
                    <button
                        type="button"
                        onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
                        disabled={pagination.state.page + Math.floor(pageButtonCount / 2) > pagination.state.getTotalPages(data.nodes)}
                    >
                        {">"}
                    </button>
                </div>
            </div>
        </div>

    )
}

export { UsersList }