import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../../store/auth/authActions';
import Styles from './navbar.module.scss'
import Logo from '../../assets/app/logo.png'
import { FiHome, FiCalendar, FiMessageSquare, FiSliders, FiLogOut, FiMenu, FiX } from 'react-icons/fi';
import { getImage, getProfileStudent } from '../../utils';
import { MenuItem } from './menuItem';
import { createStudent, switchToStudentProfile } from '../../store/profile/profileActions';
import { FaMoneyBillTransfer, FaMoneyCheck } from 'react-icons/fa6';

const NavbarTutor = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { user} = useSelector((state) => state.auth);
	const { appointments, messages, wallet } = useSelector(state => state.notification)
	const { currentProfile } = useSelector((state) => state.profile);

	const [menuOpen, setMenuOpen] = useState(false);
	
	const studentProfile = getProfileStudent();

	const logoutHandler = () => {
		dispatch(logoutUser());
	};

	const switchToStudent = () => {
		dispatch(switchToStudentProfile())
			.then(() => navigate("/"))
	}

	const createStudentProfile = () => {
		dispatch(createStudent())
			.unwrap()
			.then(() => {
				navigate("/", { replace: true })
			})
	}

	const SwitchProfile = () => {
		if (studentProfile !== false)
			return <div onClick={() => switchToStudent()} className='inline-table mx-auto self-end left-[25px] bg-nt-blue w-[90%] mb-5 text-sm text-white py-2 px-5 rounded cursor-pointer'>
				Switch to Student
			</div>
		else return <div onClick={() => createStudentProfile()} className='inline-table mx-auto self-end left-[25px] bg-nt-blue w-[90%] mb-5 text-sm text-white py-2 px-5 rounded cursor-pointer'>
			Become a student
		</div>
	}

	return (
		<>
			<div className='fixed xl:hidden bg-white w-full flex z-50 justify-center items-center py-2'>
				<div className='xl:hidden'>
					<button onClick={() => setMenuOpen((old) => !old)} className='absolute left-2 top-2 p-2 rounded-full bg-nt-blue text-white grid place-items-center shadow-md'>
						{!menuOpen ? <FiMenu size={24} /> : <FiX size={24} />}
					</button>
				</div>
				<img src={Logo} className='self-center justify-self-center' />

			</div>
			<div className={`w-[300px] overflow-y-auto h-[calc(100%-3.5rem)] xl:min-h-screen rounded-tr-3xl rounded-br-3xl flex flex-col bg-white 
				items-start p-6 z-50 fixed transition-transform top-14 xl:top-0
			${menuOpen ? "translate-x-0" : " -translate-x-full"} xl:translate-x-0`}>
				<img src={Logo} className='hidden xl:block mb-5' />
				<div className={'w-full flex-1'}>
					<ul className={`space-y-3 ${!currentProfile.lastStep ? "pointer-events-none select-none opacity-60" : ''}`}>
						<li>
							<MenuItem menuOpen={() => setMenuOpen(false)} title="Dashboard" Icon={FiHome} link='/' />
						</li>
						<li>
							<MenuItem menuOpen={() => setMenuOpen(false)} title="Appointments" Icon={FiCalendar} link='/appointment' notification={appointments} />
						</li>
						<li>
							<MenuItem menuOpen={() => setMenuOpen(false)} title="Calendar" Icon={FiCalendar} link='/calendar' />
						</li>
						<li>
							<MenuItem menuOpen={() => setMenuOpen(false)} title="Chat" Icon={FiMessageSquare} link='/chat' notification={messages} />
						</li>
						<li>
							<MenuItem menuOpen={() => setMenuOpen(false)} title="Transactions" Icon={FaMoneyBillTransfer} link='/wallet' notification={wallet} />
						</li>
						<li>
							<MenuItem menuOpen={() => setMenuOpen(false)} title="Withdraw" Icon={FaMoneyCheck} link='/withdraw' />
						</li>
						<li>
							<MenuItem menuOpen={() => setMenuOpen(false)} title="Settings" Icon={FiSliders} link='/settings' />
						</li>
					</ul>
				</div>

				<SwitchProfile />

				<div className={'flex gap-2 items-center justify-between text-sm w-full border-t-2 border-nt-grey-light pt-2'}>
					<img src={getImage(user.avatar, "AVATAR")} className='w-12 h-12 rounded-full object-cover' />
					<div className={'flex flex-col grow'}>
						<div className={'text-nt-blue font-semibold capitalize'}>{user.firstName + ' ' + user.lastName}</div>
						<div className={'text-nt-grey'}>Tutor</div>
					</div>
					<div className='w-8 cursor-pointer'>
						<FiLogOut onClick={() => logoutHandler()} className={Styles.logoutBtn} />
					</div>
				</div>
			</div>
		</>
	);
};

export { NavbarTutor };
