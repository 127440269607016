import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import { usePagination } from "@table-library/react-table-library/pagination";
import Styles from '../users/users.module.scss'
import { FiFilter, FiPlus } from "react-icons/fi";
import { getImage } from "../../utils";

import NotFoundWidget from '../../components/widgets/NotFound';

import { acceptWithdraw, addWithdrawRequet, getAllWithdraw, getWithdrawList, rejectWithdraw, } from "../../store/wallet/walletActions";

const TransactionList = ({ setIsRequest, initView, nodes, setNodes }) => {
    const dispatch = useDispatch()
    const { currentProfile } = useSelector(state => state.profile)
    const [filter, setFilter] = useState([]);

    const COLUMNS = [
        {
            label: 'Request Date',
            renderCell: (item) => <p className="capitalize font-medium text-nt-blue">{item.createdAt}</p>,
            pinLeft: true,

        },
        {
            label: 'Amount',
            renderCell: (item) => item.amount + "€",
            pinLeft: true,

        },
        {
            label: 'Payal Email',
            renderCell: (item) => item.email,
            pinLeft: true,
        },
        {
            label: 'Status',
            renderCell: (item, index) => {
                return <StatusTag status={item.status} />
            },
            pinLeft: true,

        },
    ];

    if (currentProfile.role === 1) {
        COLUMNS.unshift({
            label: 'Tutor',
            renderCell: (item, index) => {
                return <div className="flex gap-2 capitalize text-nt-blue items-center">
                    {item.tutor?.userId?.avatar?.path ? <img className={Styles.avatar} src={getImage(item.tutor?.userId?.avatar?.path)} /> : ''}
                    {item.tutor?.userId ? item.tutor?.userId?.firstName + " " + item.tutor?.userId?.lastName : ''}
                </div>
            },
            pinLeft: true,
        })
        COLUMNS.push({
            label: 'Action',
            renderCell: (item, index) => {
                return <>{item.status == -1 ? <div className="flex gap-1">
                    <button type="button" className="rounded-md bg-nt-blue text-white px-2 py-1" onClick={() => handleAcceptWithdraw(item._id)}>Accept</button>
                    <button type="button" className="rounded-md bg-nt-red text-white px-2 py-1" onClick={() => handleRejectWithdraw(item._id)}>Reject</button>
                </div> : ''}</>
            },
            pinLeft: true,
        })
    }

    const handleAcceptWithdraw = (id) => {
        dispatch(acceptWithdraw(id))
            .unwrap()
            .then(() => {
                setNodes((n) => n.map(k => ({ ...k, status: k._id === id ? 1 : k.status })))
            })
    }

    const handleRejectWithdraw = (id) => {
        dispatch(rejectWithdraw(id))
            .unwrap()
            .then(() => {
                setNodes((n) => n.map(k => ({ ...k, status: k._id === id ? 0 : k.status })))
            })
    }

    const StatusTag = ({ status }) => {
        if (status === -1) {
            return <span className={`bg-nt-blue text-white px-2 rounded py-1 inline-block`}>Pending</span>
        } else if (status === 1) {
            return <span className={`bg-green text-white px-2 rounded py-1 inline-block`}>Accepted</span>
        } else if (status === 0) {
            return <span className={`bg-red text-white px-2 rounded py-1 inline-block`}>Rejected</span>
        }
    }
    const theme = useTheme([getTheme(), {
        HeaderRow: `color: #191D51;`,
        Table: `--data-table-library_grid-template-columns: ${currentProfile.role == 1 ? 'auto auto' : ''} auto auto auto auto ; width:auto;min-width:auto;min-height:auto;`
    }]);

    let data = { nodes };

    data = {
        nodes: data.nodes.filter((item) =>
            (filter.length && filter.includes(item.status) || filter.length === 0)
        ),
    };

    const pagination = usePagination(data, {
        state: {
            page: 0,
            size: 5,
        },
        onChange: onPaginationChange,
    });

    const pageButtonCount = 5;
    const [minVisibleBtn, setMinVisibleBtn] = useState(0)
    const [maxVisibleBtn, setMaxVisibleBtn] = useState(pageButtonCount)
    function onPaginationChange(action, state) {
        setMinVisibleBtn(state.page - Math.floor(pageButtonCount / 2) >= 0 ? state.page - Math.floor(pageButtonCount / 2) : 0)
        setMaxVisibleBtn((minVisibleBtn + pageButtonCount) - 1)
    }

    const toggleFilter = (status) => {
        pagination.fns.onSetPage(0)
        if (filter.includes(status))
            setFilter(x => x.filter(v => v != status))
        else
            setFilter(x => [...x, status])
    }

    return <div className="w-full">
        <div className="bg-white p-4 rounded-2xl">
            <div className={Styles.tableHeader + " items-center"}>
                <h2>Withdraw List</h2>

                {currentProfile.role !== 1 && <button className="bg-nt-blue rounded text-white pl-2 pe-3 py-2 ml-2 flex items-center gap-2" onClick={() => setIsRequest(true)}><FiPlus /> Add New Request</button>}
                {currentProfile.role === 1 && <div className="flex gap-1 mb-5 items-center">
                    <FiFilter className="text-nt-blue mr-2" size={18} />
                    <span onClick={() => toggleFilter(-1)} className={`${filter.includes(-1) ? "text-white bg-nt-blue" : "text-nt-blue"} border-[1px] select-none rounded p-1 cursor-pointer px-2`}>Pending</span>
                    <span onClick={() => toggleFilter(1)} className={`${filter.includes(1) ? "text-white bg-nt-blue" : "text-nt-blue"} border-[1px] select-none rounded p-1 cursor-pointer px-2`}>Accepted</span>
                    <span onClick={() => toggleFilter(0)} className={`${filter.includes(0) ? "text-white bg-nt-blue" : "text-nt-blue"} border-[1px] select-none rounded p-1 cursor-pointer px-2`}>Rejected</span>

                </div>}
            </div>

            {currentProfile.role !== 1 && <div className="flex gap-1 mb-5 items-center">
                <FiFilter className="text-nt-blue mr-2" size={18} />
                <span onClick={() => toggleFilter(-1)} className={`${filter.includes(-1) ? "text-white bg-nt-blue" : "text-nt-blue"} border-[1px] select-none rounded p-1 cursor-pointer px-2`}>Pending</span>
                <span onClick={() => toggleFilter(1)} className={`${filter.includes(1) ? "text-white bg-nt-blue" : "text-nt-blue"} border-[1px] select-none rounded p-1 cursor-pointer px-2`}>Accepted</span>
                <span onClick={() => toggleFilter(0)} className={`${filter.includes(0) ? "text-white bg-nt-blue" : "text-nt-blue"} border-[1px] select-none rounded p-1 cursor-pointer px-2`}>Rejected</span>

            </div>}
            <CompactTable columns={COLUMNS} layout={{ custom: true, horizontalScroll: true, }} data={data} theme={theme} pagination={pagination} />
            {nodes.length === 0 ? <NotFoundWidget message="No Withdraw History" /> : null}

            <div className={Styles.pagination}>
                <div className={Styles.paginationInfos}>
                    Total pages: {pagination.state.getTotalPages(data.nodes)}
                </div>
                <div className={Styles.paginationButtons}>
                    <button
                        type="button"
                        disabled={minVisibleBtn === 0}
                        onClick={() => pagination.fns.onSetPage(pagination.state.page - 1)}
                    >
                        {"<"}
                    </button>
                    {pagination.state.getPages(data.nodes).map((_, index) => {
                        return (minVisibleBtn <= index && maxVisibleBtn >= index) && <button
                            key={index}
                            type="button"
                            className={pagination.state.page === index ? Styles.active : ''}
                            onClick={() => pagination.fns.onSetPage(index)}
                        >
                            {index + 1}
                        </button>

                    })}
                    <button
                        type="button"
                        onClick={() => pagination.fns.onSetPage(pagination.state.page + 1)}
                        disabled={pagination.state.page + Math.floor(pageButtonCount / 2) > pagination.state.getTotalPages(data.nodes)}
                    >
                        {">"}
                    </button>
                </div>
            </div>
        </div>
    </div>
}
export { TransactionList }