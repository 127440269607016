import { useForm } from "react-hook-form";
import { Languages } from "../auth/register/languagesInput"
import { SelectInput, TextInput } from "../inputs";
import Langs from '../../utils/data/langs.json'
import countries from "../../utils/data/countries.json"
import { useDispatch } from "react-redux";
import { searchTutor } from "../../store/profile/profileActions";
import { useState } from "react";

const SearchTutorBar = ({setTutors}) => {
    const {
        register,
        handleSubmit,
        reset,
        control,
        watch,
        getValues,
        errors,
    } = useForm();

    const languages = Langs.map((lang) => { return { value: lang.value, label: lang.name + " - " + lang.nativeName } })
    const options = countries.map((country) => { return { value: country.iso, label: country.name } })

    const dispatch = useDispatch();
    const [oldSignal, setOldSignal] = useState(null)

    const handleSearch = () => {
        const controller = new AbortController();
        if (oldSignal !== null) {
            oldSignal.abort()
        }
        setOldSignal(controller)
        const data = getValues()
        console.log(data)
        dispatch(searchTutor({ signal: controller.signal, ...data }))
        .unwrap()
        .then(v => setTutors(v))
        .catch(e => console.log("e", e)) 
    }

    return (
        <div className="flex flex-col xl:flex-row w-full">
            <SelectInput
                name={`teach`}
                onChangeSelect={() => handleSearch()}
                label=""
                errors={errors}
                register={register}
                control={control}
                options={languages}
                placeholder="I want to learn"
                styles={{ width: "100%", }}
                isSearchable={true}
                isClearable={true}

            />
            <SelectInput
                name={`speak`}
                onChangeSelect={() => handleSearch()}
                label=""
                errors={errors}
                register={register}
                control={control}
                options={languages}
                placeholder="I speak"
                styles={{ width: "100%" }}
                // isSearchable={true}
                // isMulti={true}
                isClearable={true}
            />
            <SelectInput
                name={`country`}
                label=""
                onChangeSelect={() => handleSearch()}
                errors={errors}
                register={register}
                control={control}
                options={options}
                placeholder="Country"
                styles={{ width: "100%" }}
                isSearchable={true}
                // isMulti={true}
                isClearable={true}
            />
            <div className="w-full h-fit border-b-[1px] border-nt-grey pb-1">

                <div className="relative text-left">
                    <input
                        className={'mt-2 h-[38px] text-[18px] placeholder:text-[#808080] border-b-[1px]  ps-2 w-full text-nt-blue rounded'}
                        name={"price"}
                        placeholder={"Price"}
                        type="number"
                        {...register("price", {onChange: () => handleSearch()})}
                    />
                </div>
            </div>
        </div>
    )
}

export { SearchTutorBar }